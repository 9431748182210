// src/TP/PlannerBundle/Resources/public/js/print.js

import participants from 'tp_planner/js/print/participants.js';
import resize from 'tp_planner/js/print/resize.js';
import pageBreaks from 'tp_planner/js/print/pagebreak.js';
import splitSheets from 'tp_planner/js/print/split-sheets.js';
import images from 'tp_planner/js/print/images.js';
import display from 'tp_planner/js/print/display.js';

import 'paper-css/paper.css';
import 'raleway-webfont/raleway.css';
import 'tp_planner/css/print.scss';

export default (function() {
    return {
        initialize
    };

    function initialize() {
        if ($('body#ProgramPrint').length > 0) {
            $(function() {
                //Manage images which are at risk of not being loaded
                images.manageRiskyImages();
            });

            // Wait for the whole page to be loaded (especialy images) before making treatements
            $(window).on('load', function() {
                // Document modifications
                pageBreaks.removeEmptySelectableElements();
                resize.resizeImages();
                resize.resizeTitles();
                pageBreaks.initialize();
                display.notebookDisplay();
                if ($('.program-print-voucher').length > 0) {
                    participants.organiseVoucherParticipants();
                }

                // Must come after the other modifications
                let nodeActions = {};
                if ($('.program-print-frontPage').length > 0) {
                    nodeActions['.program-print-participants-title:eq(0)'] =
                        participants.organiseFrontPageParticipants;
                }
                splitSheets.manageSheetsContent(nodeActions);
                display.addPagination();

                // Display the document
                display.printButton();
                display.displayDocument();

                // Scroll, done at the end otherwise the anchor messes with the splitting process
                pageBreaks.scrollToClickedPageBreak();
            });
        }
    }
})();
