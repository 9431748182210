// src/TP/CoreBundle/Resources/public/js/documentation.js

import splitSheets from 'tp_planner/js/print/split-sheets.js';
import display from 'tp_planner/js/print/display.js';

import 'paper-css/paper.css';
import 'raleway-webfont/raleway.css';
import 'tp_planner/css/print.scss';

import 'tp_core/css/documentation.scss';
import 'tp_core/images/documentationImages.js';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        if ($('body#DocumentationPrint').length > 0) {
            // Wait for the whole page to be loaded (especialy images) before making treatements
            $(window).on('load', function() {
                // Treatments
                tableOfContents();
                splitSheets.manageSheetsContent();

                // Display the document
                display.printButton();
                display.displayDocument();
            });
        }
    }

    function tableOfContents() {
        const titlesDOM = ['h1', 'h2', 'h3', 'h4', 'h5'];
        let titles = {};

        $(titlesDOM.join(',')).each(function(index) {
            if (index > 0) {
                const scroll = parseInt($(this).offset().top);
                titles[scroll] = {
                    dom: this.tagName.toLowerCase(),
                    text: $(this).text()
                };
            }
        });

        for (const scroll in titles) {
            $('#TableOfContents ul').append(
                $('<li>')
                    .addClass('tp-core-title-' + titles[scroll].dom)
                    .text(titles[scroll].text)
            );
        }
    }
})();
