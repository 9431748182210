// src/TP/DolibarrBundle/Resources/public/js/dolibarr.js

import dialog from 'tp_table/js/dialog.js';

export default (function() {
    return {
        printInNewTab: printInNewTab,
        administration: administration
    };

    function printInNewTab() {
        if (window.self !== window.top) {
            $('.program-header #Program-print').click(function() {
                window.open($(this).attr('href'));
                return false;
            });
        }
    }

    function administration() {
        $('#DolibarrSubmitButton').click(function() {
            dialog.loading($(this).data('running-title'));
        });
    }
})();
