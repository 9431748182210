// src/TP/CoreBundle/Resources/public/js/homepage.js

import datatables from 'tp_table/js/datatables.js';
import dialog from 'tp_table/js/dialog.js';

import 'tp_core/css/homepage.scss';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        myPrograms();
        nextMonthDeparture();
        programStatus();
        ownersRevenue();
    }

    function myPrograms() {
        let options = {
            searching: false,
            paging: false,
            info: false,
            columnDefs: [
                { visible: false, targets: [4, 8, 10, 11] },
                { orderable: false, targets: '_all' }
            ]
        };

        datatables.display('list', '.card.tp-myPrograms', options);
    }

    function nextMonthDeparture() {
        let options = {
            searching: false,
            paging: false,
            info: false,
            columnDefs: [
                { visible: false, targets: [8, 10, 11] },
                { orderable: false, targets: '_all' }
            ]
        };
        options.columnDefs.push({ orderData: 9, targets: 2 });

        datatables.display('list', '.card.tp-nextMonthDepartures', options);
    }

    function programStatus() {
        let popupContent = $('#ProgramsStatusForWholeTeam').clone();
        $('#ShowProgramsStatusForWholeTeam').click(function() {
            dialog.open($(this).data('title'), popupContent, 700, true);
        });
    }

    function ownersRevenue() {
        let popupContent = $('#OwnersRevenueForWholeTeam').clone();
        $('#ShowOwnersRevenueForWholeTeam').click(function() {
            dialog.open($(this).data('title'), popupContent, 700, true);
        });

        $('#ShowOwnerRevenue').click(function() {
            $(this).toggle();
            $(this)
                .prev()
                .toggleClass('invisible');
        });
    }
})();
