// src/TP/CoreBundle/Resources/public/js/maintenance.js

import dialog from 'tp_table/js/dialog.js';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        $('#MaintenanceAskConfirmation').click(function(event) {
            event.preventDefault();

            let buttonYes = $('<button>')
                .attr('id', 'MaintenanceSubmitButton')
                .addClass('btn btn-danger btn-sm')
                .text($(this).data('confirmation-yes'));
            let buttonNo = $('<button>')
                .attr('id', 'MaintenanceCancel')
                .addClass('btn btn-default btn-sm')
                .text($(this).data('confirmation-no'));
            let content = $('<div>')
                .addClass('col-sm-12')
                .append(buttonYes)
                .append(buttonNo);

            dialog.open($(this).data('confirmation-question'), content);
        });

        $(dialog.DIALOG_ID).on('click', '#MaintenanceSubmitButton', function() {
            dialog.loading(
                $('#MaintenanceAskConfirmation').data('running-title')
            );

            $('form').submit();
        });

        $(dialog.DIALOG_ID).on('click', '#MaintenanceCancel', function() {
            dialog.close();
        });
    }
})();
