// src/TP/TableBundle/Resources/public/js/entity-popup.js

import dialog from 'tp_table/js/dialog.js';
import datatables from 'tp_table/js/datatables.js';

export default (function() {
    let DIALOG_POPUP = '#TP_Table_EntityPopup';

    return {
        initialize: initialize
    };

    function initialize() {
        dialog.initialize(DIALOG_POPUP);
        $('.table-form-row,' + dialog.DIALOG_ID).on(
            'click',
            '.tp-table-entity-popup .input-group-addon',
            function() {
                displayPopup($(this));
            }
        );
    }

    function displayPopup(popupTrigger) {
        // Initialisation
        let title = popupTrigger
            .closest('.form-group')
            .children('label')
            .text();
        let table = popupTrigger.data('tp-table');
        let filter = popupTrigger.data('filter');
        let filterIds = null;

        let select = popupTrigger.parent().children('select');
        let multiple = select.attr('multiple');
        let value = select.val();

        if (filter) {
            let options = select.children('option[value!=""]:enabled');
            filterIds = options
                .map(function() {
                    return $(this).val();
                })
                .get();
        }

        // Loading Popup
        dialog.loading(title, DIALOG_POPUP);

        // Get the Popup View
        $.post(
            // URL
            Routing.generate('tp_table_popup', {
                table: table,
                select: 'select'
            }),
            // Data
            { filterIds: filterIds },
            // Success
            function(data) {
                // Display the Popup View
                dialog.open(
                    title,
                    data,
                    $('.container:eq(0)').width() + 100,
                    true,
                    undefined,
                    DIALOG_POPUP
                );

                // Select in the Popup the Records selected in the Form
                if (value !== '') {
                    let template = '.tp-table-popup-checkbox[data-id=%s]';
                    let selected;
                    if (multiple) {
                        selected = value
                            .map(x => template.replace('%s', x))
                            .join(',');
                    } else {
                        selected = template.replace('%s', value);
                    }
                    $(selected).prop('checked', true);
                }

                // Keep Track of Selected Records
                let selectedRecords = [];
                if (multiple) {
                    selectedRecords = value;
                } else {
                    if (value !== '') {
                        selectedRecords = [value];
                    }
                }

                // Update the Submit Button label
                let nbRecords = selectedRecords.length;
                displayRecordsNumber(nbRecords);

                // Apply the Datatable Library to the List of Records
                let customOptions = {
                    drawCallback: function() {
                        dialog.center(DIALOG_POPUP);
                    }
                };
                datatables.display('popup', DIALOG_POPUP, customOptions);

                // EVENT : Submit if Single / Keep Track and Update Submit Button if Multiple
                $(DIALOG_POPUP).off('change', '.tp-table-popup-checkbox');
                $(DIALOG_POPUP).on(
                    'change',
                    '.tp-table-popup-checkbox',
                    function() {
                        // Intialisation
                        let checked = $(this).prop('checked');
                        let recordId = $(this)
                            .data('id')
                            .toString();

                        if (!multiple && checked) {
                            // If Single and a Record has been Selected, then Select the Record and Close the Popup
                            submitPopupValue(select, recordId);
                        } else {
                            // Else keep track of Selected Records
                            if (checked) {
                                nbRecords++;
                                selectedRecords.push(recordId);
                            } else {
                                nbRecords--;
                                selectedRecords.splice(
                                    selectedRecords.indexOf(recordId),
                                    1
                                );
                            }
                            displayRecordsNumber(nbRecords);
                        }
                    }
                );

                // EVENT : Submit Button
                $('#PopupBarSubmitButton').click(function() {
                    if (selectedRecords.length == 0) {
                        selectedRecords = null;
                    }

                    submitPopupValue(select, selectedRecords);
                });
            }
        );
    }

    function displayRecordsNumber(nbRecords) {
        $('#PopupBarNbRecords').text(nbRecords);

        if (nbRecords > 1) {
            $('#PopupBarSingleRecord').hide();
            $('#PopupBarMultipleRecords').show();
        } else {
            $('#PopupBarSingleRecord').show();
            $('#PopupBarMultipleRecords').hide();
        }
    }

    function submitPopupValue(select, value) {
        select.val(value);
        select.trigger('change');
        dialog.close(DIALOG_POPUP);
    }
})();
