// src/TP/CoreBundle/Resources/public/tp_core.js
import 'jquery';

import 'bootstrap';
import 'tp_core/css/bootstrap.less';

import 'tp_core/css/layout.scss';
import 'tp_core/css/error.scss';

import 'tp_planner/tp_planner.js';
import 'tp_dolibarr/tp_dolibarr.js';
import 'tp_user/tp_user.js';

import dialog from 'tp_table/js/dialog.js';

import homepage from 'tp_core/js/homepage.js';
import maintenance from 'tp_core/js/maintenance.js';
import documentation from 'tp_core/js/documentation.js';
import 'tp_core/images/favicon.js';

import 'app/css/custom.scss';

$(function() {
    homepage.initialize();
    maintenance.initialize();

    // ToolTips
    $('[data-toggle="tooltip"]').tooltip();

    // Dev options
    if (!_PRODUCTION) {
        // Handle AJAX errors
        $(document).ajaxError(function(event, jqxhr, settings) {
            if (jqxhr.responseText !== undefined) {
                dialog.open(
                    'AJAX ERROR - URL : ' + settings.url,
                    jqxhr.responseText,
                    '1300',
                    true
                );
            }
        });

        // Dark Mode
        let darkmodeCookie = document.cookie.match(
            '(^|;) ?fitmytrip_darkmode=([^;]*)(;|$)'
        );
        let darkmode = darkmodeCookie ? darkmodeCookie[2] == 'true' : false;
        let containers = 'body, .container';

        if (darkmode) $(containers).addClass('dark-mode');
        $('#navbar').append(
            $('<i>').addClass('glyphicon glyphicon-lamp dark-mode-button')
        );

        $('.dark-mode-button').click(function() {
            darkmode = !darkmode;
            $(containers).toggleClass('dark-mode');
            document.cookie =
                'fitmytrip_darkmode=' + darkmode + ';domain=.localhost';
        });
    }
});

// Executed at onload event (must not be included in ready event)
documentation.initialize();
