// src/TP/DolibarrBundle/Resources/public/tp_dolibarr.js

import dolibarr from 'tp_dolibarr/js/dolibarr.js';

import 'tp_dolibarr/css/administration.scss';

$(function() {
    dolibarr.printInNewTab();
    dolibarr.administration();
});
