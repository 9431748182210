// src/TP/TableBundle/Resources/public/js/buttonsBar.js

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        $('.tp-table-button-submit').click(function() {
            const form = getForm($(this));

            const regex = /[&?]createAndAdd=1/gi;
            const newAction = form.get(0).action.replace(regex, '');

            submitForm(form, newAction);
        });

        $('.tp-table-button-add-and-new').click(function() {
            const form = getForm($(this));

            const prefix = form.get(0).action.indexOf('?') == -1 ? '?' : '&';
            const newAction = form.get(0).action + prefix + 'createAndAdd=1';

            submitForm(form, newAction);
        });
    }

    function getForm(button) {
        const formName = button.parent().data('form-name');
        return $('.table-form-row form[name="' + formName + '"]');
    }

    function submitForm(form, newAction) {
        form.get(0).action = newAction;
        //Use the click event instead of submitting the form so that the browser validate the form (like required option)
        form.find('button[type="submit"]').click();
    }
})();
