// src/TP/CoreBundle/Resources/public/images/documentationImages.js

import 'tp_core/images/documentation/doc-client-menu.png';
import 'tp_core/images/documentation/doc-client-add.png';
import 'tp_core/images/documentation/doc-country-menu.png';
import 'tp_core/images/documentation/doc-country-add.png';
import 'tp_core/images/documentation/doc-city-menu.png';
import 'tp_core/images/documentation/doc-city-add.png';
import 'tp_core/images/documentation/doc-program-menu.png';
import 'tp_core/images/documentation/doc-program-add.png';
import 'tp_core/images/documentation/doc-day-add-drag.gif';
import 'tp_core/images/documentation/doc-day-add-drag-print.png';
import 'tp_core/images/documentation/doc-day-add.png';
import 'tp_core/images/documentation/doc-service-menu.png';
import 'tp_core/images/documentation/doc-service-add.png';
import 'tp_core/images/documentation/doc-activity-add-empty-1.png';
import 'tp_core/images/documentation/doc-activity-add-empty-2.png';
import 'tp_core/images/documentation/doc-activity-add-1.png';
import 'tp_core/images/documentation/doc-activity-add-2.png';
import 'tp_core/images/documentation/doc-activity-add-3.png';
import 'tp_core/images/documentation/doc-currency-menu.png';
import 'tp_core/images/documentation/doc-currency-add.png';
import 'tp_core/images/documentation/doc-cotation-add-1.png';
import 'tp_core/images/documentation/doc-cotation-add-2.png';
import 'tp_core/images/documentation/doc-cotation-add-3.png';
import 'tp_core/images/documentation/doc-program-print-1.png';
import 'tp_core/images/documentation/doc-program-print-2.png';
