// src/TP/PlannerBundle/Resources/public/js/city-fields.js

import dialog from 'tp_table/js/dialog.js';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        $('.table-form-row,' + dialog.DIALOG_ID).on(
            'change',
            '[id$="_countries"],[id$="_country"]',
            function() {
                let formSelector = $(this)
                    .attr('id')
                    .split('_');
                formSelector.pop();
                formSelector = '#' + formSelector.join('_');

                let citiesKey =
                    formSelector + '_cities,' + formSelector + '_city';

                if ($(citiesKey).length && $(this).val().length) {
                    $(citiesKey)
                        .prev('.input-group-addon')
                        .children()
                        .html($('<span>').addClass('small-loading'));

                    $.post(
                        //URL
                        Routing.generate(
                            'tp_planner_service_getCitiesPerCountry_AJAX'
                        ),
                        // Data
                        { countriesOrCountryId: $(this).val() },
                        // Success
                        function(countryCities) {
                            let cities = $(citiesKey).val();
                            $(citiesKey)
                                .children('option')
                                .remove();

                            $.each(countryCities, function(index, city) {
                                $(citiesKey).append(
                                    $('<option>')
                                        .attr('value', city.cityId)
                                        .text(city.cityName)
                                );
                            });

                            $(citiesKey).val(cities);

                            $(citiesKey)
                                .prev('.input-group-addon')
                                .children()
                                .empty();
                        }
                    );
                } else {
                    $(citiesKey)
                        .children('option')
                        .remove();
                }
            }
        );
    }
})();
