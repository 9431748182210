// src/TP/PlannerBundle/Resources/public/js/print/resize.js

export default (function() {
    return {
        resizeTitles,
        resizeImages
    };

    function resizeTitles() {
        $('.program-print-day-name, .program-print-accomodation-city').each(
            function() {
                let fontSize = 20;
                while ($(this).height() > 22 && fontSize >= 8) {
                    fontSize--;
                    $(this).css('font-size', fontSize + 'px');
                }
            }
        );
    }

    function resizeImages() {
        $('.program-print-images').each(function() {
            // Get images
            let images = $(this).children('img');
            let nbImages = images.length;

            // Check if a resize is needed (more than 1 picture)
            if (nbImages > 1) {
                // Initialisation
                let totalWidth = 181;
                let margin = 2;
                let maxHeight = 50;

                // Get images' dimensions
                let w = [];
                let h = [];
                let factors = [];

                images.each(function(i) {
                    w[i] = $(this).width();
                    h[i] = $(this).height();
                });

                // Calculate the resizing factor for each image
                if (nbImages == 2) {
                    factors[0] =
                        (totalWidth - margin) / (w[0] + (h[0] / h[1]) * w[1]);
                    factors[1] =
                        (totalWidth - margin) / ((h[1] / h[0]) * w[0] + w[1]);
                } else if (nbImages == 3) {
                    factors[0] =
                        (totalWidth - 2 * margin) /
                        (w[0] + (h[0] / h[1]) * w[1] + (h[0] / h[2]) * w[2]);
                    factors[1] =
                        (totalWidth - 2 * margin) /
                        ((h[1] / h[0]) * w[0] + w[1] + (h[1] / h[2]) * w[2]);
                    factors[2] =
                        (totalWidth - 2 * margin) /
                        ((h[2] / h[0]) * w[0] + (h[2] / h[1]) * w[1] + w[2]);
                }

                if (factors[0] * h[0] > maxHeight) {
                    for (let i = 0; i < factors.length; i++) {
                        factors[i] = maxHeight / h[i];
                    }
                }

                // Apply the change to each image
                images.each(function(i) {
                    $(this).width(w[i] * factors[i] + 'mm');
                    $(this).height(h[i] * factors[i] + 'mm');
                });
            }
        });
    }
})();
