// src/TP/CoreBundle/Resources/public/images/favicon.js
import 'tp_core/images/favicon/apple-icon-57x57.png';
import 'tp_core/images/favicon/apple-icon-60x60.png';
import 'tp_core/images/favicon/apple-icon-72x72.png';
import 'tp_core/images/favicon/apple-icon-76x76.png';
import 'tp_core/images/favicon/apple-icon-114x114.png';
import 'tp_core/images/favicon/apple-icon-120x120.png';
import 'tp_core/images/favicon/apple-icon-144x144.png';
import 'tp_core/images/favicon/apple-icon-152x152.png';
import 'tp_core/images/favicon/apple-icon-180x180.png';

import 'tp_core/images/favicon/android-icon-192x192.png';

import 'tp_core/images/favicon/favicon-32x32.png';
import 'tp_core/images/favicon/favicon-96x96.png';
import 'tp_core/images/favicon/favicon-16x16.png';

import 'tp_core/images/favicon/ms-icon-144x144.png';
