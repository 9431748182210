// src/TP/TableBundle/Resources/public/js/map.js

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-extra-markers';
import 'leaflet-extra-markers/src/assets/css/leaflet.extra-markers.css';

import 'leaflet-control-geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

import 'tp_table/css/map.scss';

export default (function() {
    return {
        L: L,
        initialize: initialize,
        displayInputValue: displayInputValue,
        displayMap: displayMap,
        displayMarkers: displayMarkers,
        drawLine: drawLine,
        formatInputValue: formatInputValue
    };

    function initialize() {
        displayZoomMap();
        displayFormMap();

        // Prevent Form Submission from the Map
        $('.tp-table-map-container').on('keyup keypress', 'input', function(e) {
            var keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });
    }

    // ****************************************
    // Location Displayed Value
    // ****************************************
    function displayInputValue(container = '') {
        $(container + ' .tp-table-map').each(function() {
            updateDisplayedValue($(this).find('input'));
        });
    }

    function updateDisplayedValue(input) {
        let inputValue = getInputValue(input);
        let container = $('#tp_table_map_location_' + input.attr('id'));
        let markersProperties = $('#tp_table_map_' + input.attr('id')).data(
            'markers'
        );

        if (Object.keys(inputValue).length == 0) {
            container.text('');
        } else {
            let displayedValues = Object.keys(inputValue).reduce(function(
                displayedValues,
                marker
            ) {
                displayedValues.push(
                    markersProperties[marker].label +
                        ': [Latitude: ' +
                        inputValue[marker].center[0] +
                        ' | Longitude: ' +
                        inputValue[marker].center[1] +
                        ']'
                );
                return displayedValues;
            },
            []);
            container.text(displayedValues.join(', '));
        }
    }

    // ****************************************
    // Display Map
    // ****************************************
    function displayZoomMap() {
        $('.tp-table-map-container-zoom').each(function() {
            // Retrieve data
            let location = formatInputValue($(this).data('input-value'));
            let markersProperties = $(this).data('markers');

            // Display the map & markers
            let map = displayMap($(this).attr('id'), location);
            displayMarkers(map, location, markersProperties);

            // Draw a line between the markers
            drawLine(map, location);
        });
    }

    function displayMap(containerId, location, options = undefined) {
        let map = L.map(containerId, options);

        let bounds = getDisplayBounds(location);
        if (bounds.length == 1) {
            map.setView(bounds[0], location.zoom);
        } else {
            map.fitBounds(bounds, { padding: [40, 40] });
        }

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        return map;
    }

    function displayMarkers(map, location, markersProperties) {
        let lastMarker;

        if (location.displayMarker) {
            for (let marker in location.markers) {
                lastMarker = addMarker(
                    map,
                    location.markers[marker].center,
                    markersProperties[marker]
                );
            }
        }

        return lastMarker;
    }

    function drawLine(map, location) {
        if (Object.keys(location.markers).length > 1) {
            let points = Object.keys(location.markers).reduce(function(
                points,
                markerKey
            ) {
                points.push(location.markers[markerKey].center);
                return points;
            },
            []);
            // L.polyline(points, { color: '#660066' }).addTo(map);
            L.polyline(points, { color: '#4d4d4d' }).addTo(map);
        }
    }

    function addMarker(map, markerCenter, markerOptions) {
        markerOptions.prefix = 'glyphicon';
        if (!new RegExp('^glyphicon-').test(markerOptions.icon)) {
            markerOptions.icon = 'glyphicon-' + markerOptions.icon;
        }

        let extraMarker = L.ExtraMarkers.icon(markerOptions);
        return L.marker(markerCenter, { icon: extraMarker }).addTo(map);
    }

    // ****************************************
    // Display Editable Map
    // ****************************************
    function displayFormMap() {
        $('.table-form-row').on('click', '.tp-table-map', function() {
            // Initialilize
            let input = $(this).find('input');
            let containerId = 'tp_table_map_' + input.attr('id');
            let mapContainer = $('#' + containerId);

            mapContainer.toggleClass('hidden');

            // Load the map if needed
            if (
                !mapContainer.hasClass('hidden') &&
                !mapContainer.data('loaded')
            ) {
                // Retrieve data
                let location = formatInputValue(getInputValue(input));
                let markersProperties = mapContainer.data('markers');

                // Display the map
                let map = displayMap(containerId, location);

                // Display the markers
                if (mapContainer.data('fixedMarker')) {
                    displayMarkers(map, location, markersProperties);
                } else {
                    manageEditableMarkers(
                        input,
                        map,
                        location,
                        markersProperties
                    );
                }

                // Map loaded
                mapContainer.data('loaded', true);
            }
        });
    }

    function manageEditableMarkers(input, map, location, markersProperties) {
        let activeMarker = Object.keys(markersProperties)[0];
        let mapMarkers = {};

        // Create markers controler if more than 1 marker
        if (Object.keys(markersProperties).length > 1) {
            L.Control.Markers = L.Control.extend({
                onAdd: function() {
                    let div = L.DomUtil.create(
                        'div',
                        'leaflet-bar leaflet-control tp-table-map-control-markers'
                    );

                    for (let marker in markersProperties) {
                        let label = L.DomUtil.create('label', null, div);
                        label.appendChild(
                            document.createTextNode(
                                markersProperties[marker].label
                            )
                        );

                        let input = L.DomUtil.create('input', null, label);
                        input.type = 'radio';
                        input.name = 'marker';
                        input.value = marker;
                        if (marker == activeMarker) {
                            input.checked = 'checked';
                        }
                    }

                    return div;
                }
            });
            L.control.markers = function(opts) {
                return new L.Control.Markers(opts);
            };
            let markersControl = L.control
                .markers({ position: 'topright' })
                .addTo(map);
            L.DomEvent.disableClickPropagation(markersControl._container);

            $('.tp-table-map-control-markers input').change(function() {
                activeMarker = $(this).val();
            });
        }

        // Starting position
        if (location.displayMarker) {
            for (let marker in markersProperties) {
                let markerLocation = location.markers[marker];
                if (markerLocation) {
                    mapMarkers[marker] = addEditableMarker(
                        map,
                        marker,
                        markerLocation,
                        markersProperties[marker],
                        input
                    );
                }
            }
        }

        // Update through a click on the map
        map.on('click', function(e) {
            removeMarker(mapMarkers[activeMarker], activeMarker, input);
            let markerLocation = { center: [e.latlng.lat, e.latlng.lng] };
            mapMarkers[activeMarker] = addEditableMarker(
                map,
                activeMarker,
                markerLocation,
                markersProperties[activeMarker],
                input
            );
        });

        // Update through a geolocation search
        let geocoder = L.Control.geocoder({
            defaultMarkGeocode: false,
            position: 'topleft'
        })
            .on('markgeocode', function(e) {
                removeMarker(mapMarkers[activeMarker], activeMarker, input);
                let markerLocation = {
                    center: [e.geocode.center.lat, e.geocode.center.lng],
                    bounds: [
                        [
                            e.geocode.bbox._southWest.lat,
                            e.geocode.bbox._southWest.lng
                        ],
                        [
                            e.geocode.bbox._northEast.lat,
                            e.geocode.bbox._northEast.lng
                        ]
                    ]
                };
                mapMarkers[activeMarker] = addEditableMarker(
                    map,
                    activeMarker,
                    markerLocation,
                    markersProperties[activeMarker],
                    input
                );

                map.fitBounds(e.geocode.bbox, { padding: [50, 70] });
            })
            .addTo(map);
        L.DomEvent.disableClickPropagation(geocoder._container);
        L.DomEvent.disableClickPropagation(
            $(geocoder._container)
                .find('input')
                .get(0)
        );
    }

    function addEditableMarker(
        map,
        activeMarker,
        markerLocation,
        markerOptions,
        input
    ) {
        let marker = addMarker(map, markerLocation.center, markerOptions);
        updateInput(input, activeMarker, markerLocation);

        marker.on('click', function() {
            removeMarker(marker, activeMarker, input);
        });

        return marker;
    }

    function removeMarker(marker, activeMarker, input) {
        if (marker === undefined) return;

        marker.remove();
        updateInput(input, activeMarker, null);
        return marker;
    }

    function updateInput(input, activeMarker, markerLocation) {
        let inputValue = getInputValue(input);
        if (markerLocation === null) {
            delete inputValue[activeMarker];
            if (Object.keys(inputValue).length == 0) {
                input.val(null);
            } else {
                input.val(JSON.stringify(inputValue));
            }
        } else {
            inputValue[activeMarker] = {
                center: markerLocation.center.map(x => roundCoordinate(x))
            };
            // eslint-disable-next-line prettier/prettier
            if (Object.prototype.hasOwnProperty.call(markerLocation, 'bounds')) {
                inputValue[activeMarker].bounds = markerLocation.bounds.map(x =>
                    x.map(x => roundCoordinate(x))
                );
            }

            input.val(JSON.stringify(inputValue));
        }

        updateDisplayedValue(input);
    }

    // ****************************************
    // Tools
    // ****************************************
    function formatInputValue(inputValue) {
        if (inputValue === null || Object.keys(inputValue).length == 0) {
            return {
                markers: {
                    default: {
                        center: [30, 0]
                    }
                },
                zoom: 2,
                displayMarker: false
            };
        } else {
            return {
                markers: inputValue,
                zoom: 16,
                displayMarker: true
            };
        }
    }

    function getDisplayBounds(location) {
        return Object.keys(location.markers).reduce(function(
            bounds,
            markerKey
        ) {
            let marker = location.markers[markerKey];
            if (Object.prototype.hasOwnProperty.call(marker, 'bounds')) {
                bounds.push(...marker.bounds);
            } else {
                bounds.push(marker.center);
            }
            return bounds;
        },
        []);
    }

    function getInputValue(input) {
        let inputValue = input.val();
        return inputValue == '' ? {} : JSON.parse(inputValue);
    }

    function roundCoordinate(value) {
        return Number(Math.round(value + 'e7') + 'e-7');
    }
})();
