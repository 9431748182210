// src/TP/PlannerBundle/Resources/public/js/print/participants.js

export default (function() {
    return {
        organiseFrontPageParticipants,
        organiseVoucherParticipants
    };

    function organiseFrontPageParticipants() {
        $('.program-print-frontPage .program-print-participants').each(
            function() {
                const nbParticipants = $(this).find(' div').length;
                const nbMaxLines = $(this).data('nb-max-lines');
                const nbColumns = Math.ceil(nbParticipants / nbMaxLines);

                const DOMOffset = $(this).offset().top;
                // eslint-disable-next-line prettier/prettier
                const nextDOMOffset = $(this).next().offset().top;
                const maxHeight = nextDOMOffset - DOMOffset;

                organiseParticipants($(this), nbColumns, maxHeight);
            }
        );
    }

    function organiseVoucherParticipants() {
        $('.program-print-voucher .program-print-participants').each(
            function() {
                const nbParticipants = $(this).find(' div').length;
                let nbColumns = 1;
                let nbLines = nbParticipants;

                if (Math.ceil(nbParticipants / 2) < nbLines) {
                    nbColumns = 2;
                    nbLines = Math.ceil(nbParticipants / 2);
                }
                if (Math.ceil(nbParticipants / 3) < nbLines) {
                    nbColumns = 3;
                }

                organiseParticipants($(this), nbColumns);
            }
        );
    }

    function organiseParticipants(DOM, nbColumns, maxHeight = 100000) {
        switch (nbColumns) {
            case 1:
                break;
            case 2:
                DOM.find('div')
                    .removeClass('col-xs-12')
                    .addClass('col-xs-6');
                break;
            default:
                DOM.find('div')
                    .removeClass('col-xs-12')
                    .addClass('col-xs-4');
        }

        let fontSize = 24;
        let minMaxHeights = getMinMaxParticipantsHeight(DOM);
        let height = DOM.outerHeight();

        while (
            (height > maxHeight || minMaxHeights.min < minMaxHeights.max) &&
            fontSize >= 8
        ) {
            fontSize--;
            DOM.css('font-size', fontSize);
            minMaxHeights = getMinMaxParticipantsHeight(DOM);
            height = DOM.outerHeight();
        }

        // In case this was not enought, the participants will be transfered to another page
        if (height > maxHeight || minMaxHeights.min < minMaxHeights.max) {
            DOM.removeAttr('style');
            DOM.parent()
                .find('.program-print-participants-title')
                .toggleClass('hidden');
        }
    }

    function getMinMaxParticipantsHeight(DOM) {
        const heights = DOM.find('.program-print-participant')
            .map(function() {
                return $(this).height();
            })
            .get();

        return {
            min: Math.min.apply(null, heights),
            max: Math.max.apply(null, heights)
        };
    }
})();
