// src/TP/PlannerBundle/Resources/public/js/images.js

import dialog from 'tp_table/js/dialog.js';
import imageGrid from 'tp_table/js/image-grid.js';

export default (function() {
    return {
        initialize: initialize,
        loadGrids: loadGrids
    };

    function initialize() {
        prefillName();
        loadGrids();
    }

    function prefillName() {
        $('.table-form-row,' + dialog.DIALOG_ID).on(
            'change',
            '#image_imageFile_file',
            function() {
                var filePath = $('#image_imageFile_file')
                    .val()
                    .split('\\');
                var fileName = filePath[filePath.length - 1].split('.')[0];
                $('#image_name').val(fileName);
            }
        );
    }

    function loadGrids(container = '') {
        $(container + ' .tp-table-images-grid-container').each(function() {
            let gridId = $(this).attr('id');
            let forcedFilter = $(this).data('filter');
            let objectRootId = '#' + gridId.substring(16, gridId.length - 6);

            let filter = function() {
                if (forcedFilter) {
                    return forcedFilter;
                } else {
                    let countries = $(objectRootId + 'countries').val();
                    let cities = $(objectRootId + 'cities').val();

                    let filter = {};
                    if (countries) {
                        filter.country = countries;
                    }
                    if (cities) {
                        filter.city = cities;
                    }

                    return filter;
                }
            };

            imageGrid.displayGridInForm('#' + gridId, filter);
        });
    }
})();
