// src/TP/PlannerBundle/Resources/public/tp_planner.js

import 'tp_table/tp_table.js';

import programHeader from 'tp_planner/js/program-header.js';
import dayActivities from 'tp_planner/js/day-activities.js';
import cotationBlocs from 'tp_planner/js/cotation-blocs.js';
import cityFields from 'tp_planner/js/city-fields.js';
import images from 'tp_planner/js/images.js';
import print from 'tp_planner/js/print.js';
import programForm from 'tp_planner/js/program-form';
import tracking from 'tp_planner/js/tracking';

import 'tp_planner/css/service.scss';

$(function() {
    programHeader.initialize();
    dayActivities.initialize();
    cotationBlocs.initialize();
    cityFields.initialize();
    images.initialize();
    programForm.initialize();
    tracking.initialize();
});

// Executed at onload event (must not be included in ready event)
print.initialize();
