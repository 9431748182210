// src/TP/TableBundle/Resources/public/js/form-fields.js

import 'eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'moment/locale/fr';

import 'tp_table/css/flags.scss';
import 'tp_table/css/translation.scss';

import map from 'tp_table/js/map.js';
import dialog from 'tp_table/js/dialog.js';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize(container = '') {
        // Read Only Fields
        displayReadonlyFields(container);

        // Date & Time Pickers
        datepicker(container);
        timepicker(container);

        // Map
        map.displayInputValue(container);

        // Labels - Translation
        displayFlags(container);

        // Entity Links
        activateEntityLinks(container);

        // Markdown Helper
        activateMarkdownHelper(container);
    }

    function displayReadonlyFields(container = '') {
        const fields = ['select[readonly]', 'input[type="number"][readonly]'];
        const fieldsSelector = fields.map(s => container + ' ' + s).join(',');

        $(fieldsSelector).each(function() {
            if (!$(this).data('readonly-done')) {
                $(this)
                    .addClass('hidden')
                    .attr('readonly', false);

                let id = $(this).attr('id');
                let value = $(this).val();
                let label;

                if (value) {
                    if ($(this).is('select')) {
                        if (typeof value === 'object') {
                            label = [];
                            let select = $(this);
                            $.each(value, function(key, val) {
                                label.push(
                                    select
                                        .children('option[value=' + val + ']')
                                        .text()
                                );
                            });
                            label = label.join(', ');
                        } else {
                            label = $(this)
                                .children('option[value=' + value + ']')
                                .text();
                        }
                    } else {
                        label = value;
                    }
                }

                let readOnlyField = $(
                    '<input id="' +
                        id +
                        '_label" readonly="readonly" class="form-control" type="text">'
                );
                readOnlyField.val(label);
                $(this).after(readOnlyField);

                $(this).data('readonly-done', true);
            }
        });

        $(container + ' input[type="checkbox"][readonly]').each(function() {
            if (!$(this).data('readonly-done')) {
                $(this).attr('readonly', false);
                let readOnlyField = $(this).clone();
                $(this).addClass('hidden');

                readOnlyField
                    .prop('disabled', true)
                    .attr('id', readOnlyField.attr('id') + '_readOnly');
                $(this).after(readOnlyField);

                $(this).data('readonly-done', true);
            }
        });
    }

    // Date & Time Pickers
    function datepicker(container = '') {
        $(container + ' input[type=datepicker]')
            .parent()
            .datetimepicker({
                locale: $('html').attr('lang'),
                format: 'DD/MM/YYYY',
                widgetPositioning: { horizontal: 'left' }
            });
    }

    function timepicker(container = '') {
        $(container + ' input[type=timepicker]')
            .parent()
            .datetimepicker({
                locale: $('html').attr('lang'),
                format: 'HH:mm',
                widgetPositioning: { horizontal: 'left' }
            });
    }

    // Translation
    function displayFlags(container = '') {
        $(container + ' .tp-table-translatable').each(function() {
            let flagClass = $(this).data('flag');
            let flag = $('<i>').addClass(flagClass);
            $(this).append(flag);
        });
    }

    // Entity Links
    function activateEntityLinks(container = '') {
        $(container + ' .tp-table-entity-link i').click(function() {
            window.open($(this).data('link'));
        });
    }

    // Markdown Helper
    function activateMarkdownHelper(container = '') {
        $(container + ' .tp-table-markdown span').click(function() {
            const dialogLabel = $(this).attr('title');
            dialog.loading(dialogLabel);

            $.post(
                //URL
                Routing.generate('tp_table_markdown_helper_AJAX'),

                // Success
                function(helper) {
                    dialog.open(dialogLabel, helper, 1200, true);
                }
            );
        });

        $(dialog.DIALOG_ID).on('click', '.tp-table-markdown-doc', function(e) {
            e.preventDefault();
            window.open($(this).data('url'));
        });
    }
})();
