// src/TP/PlannerBundle/Resources/public/js/print/pagebreak.js

import display from 'tp_planner/js/print/display.js';

export default (function() {
    let pageBreaks;

    return {
        removeEmptySelectableElements,
        initialize,
        scrollToClickedPageBreak
    };

    // API
    function removeEmptySelectableElements() {
        $('.program-print-pagebreak-selectable').each(function() {
            if ($(this).height() === 0) $(this).remove();
        });
    }

    function initialize() {
        pageBreaks = getPageBreaksInForm();

        // eslint-disable-next-line prettier/prettier
        $('.program-print-pagebreak-selectable .program-print-pagebreak-action').click(function() {
            display.hideDocument();

            const elementId = getElementId($(this));
            addPageBreak(elementId);
            submitForm(elementId);
        });

        // eslint-disable-next-line prettier/prettier
        $('.program-print-pagebreak-selected .program-print-pagebreak-action').click(function() {
            display.hideDocument();

            const elementId = getElementId($(this));
            removePageBreak(elementId);
            submitForm(elementId);
        });
    }

    function scrollToClickedPageBreak() {
        const clickedElementId = $('#page_break_clickedPageBreak').val();
        if (clickedElementId) {
            window.location.hash = '#' + clickedElementId;
            window.scrollBy(0, -100);
        }
    }

    // Actions
    function addPageBreak(elementId) {
        pageBreaks.push(elementId);
    }

    function removePageBreak(elementId) {
        pageBreaks = pageBreaks.filter(pb => pb !== elementId);
    }

    // Form
    function getPageBreaksInForm() {
        const pageBreaksInForm = $('#page_break_pageBreaks').val();
        return pageBreaksInForm ? JSON.parse(pageBreaksInForm) : [];
    }

    function submitForm(elementId) {
        $('#page_break_pageBreaks').val(JSON.stringify(pageBreaks));
        $('#page_break_clickedPageBreak').val(elementId);

        const urlWithoutAnchor = window.location.href.replace(/#.*$/, '');
        $('form[name="page_break"]')
            .attr('action', urlWithoutAnchor)
            .submit();
    }

    // Tools
    function getElementId(element) {
        return element.parent().attr('id');
    }
})();
