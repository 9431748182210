// src/TP/CoreBundle/Resources/public/js/homepage.js

import 'tp_user/css/sendEmail.scss';

import dialog from 'tp_table/js/dialog.js';

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        $('.tp-user-sendEmail').click(function() {
            let username = $(this).data('username');
            let popupTitle = $(this).data('popup-title');
            let popupSuccess = $(this).data('popup-success');

            dialog.loading(popupTitle);

            $.post(
                //URL
                Routing.generate('fos_user_resetting_send_email'),
                // Data
                { username: username },
                // Success
                function() {
                    let msg = $('<div>')
                        .addClass('tp-user-sendEmail-success')
                        .append(
                            $('<span>').addClass(
                                'glyphicon glyphicon-ok table-boolean-yes'
                            )
                        )
                        .append($('<span>').text(popupSuccess));

                    // ); <span class="glyphicon glyphicon-ok table-boolean-yes"></span>
                    dialog.open(popupTitle, msg, null, true);
                }
            );
        });
    }
})();
