// src/TP/PlannerBundle/Resources/public/js/tracking.js

export default (function() {
    return {
        initialize: initialize
    };

    function initialize() {
        displayActivitiesTracking();
        displayBlocsTracking();
    }

    function displayActivitiesTracking() {
        const dayTrigger = [
            '#Line_day_tracking .tp-table-tracking-more',
            '#Line_daytemplate_tracking .tp-table-tracking-more'
        ];
        const activities = '#Line_activity_tracking .tp-table-tracking-section';

        displaySubElementsTracking(dayTrigger, activities);
    }

    function displayBlocsTracking() {
        const cotationTrigger = [
            '#Line_cotation_tracking .tp-table-tracking-more',
            '#Line_cotationtemplate_tracking .tp-table-tracking-more'
        ];
        const blocs = '#Line_bloc_tracking .tp-table-tracking-section';

        displaySubElementsTracking(cotationTrigger, blocs);
    }

    function displaySubElementsTracking(parentTrigger, childrenTracking) {
        const parentSelector = parentTrigger.join(',');

        $(parentSelector).click(function() {
            $(childrenTracking).toggleClass('hidden');
            $(this)
                .children()
                .toggleClass('hidden');
        });
    }
})();
