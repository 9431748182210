// src/TP/TableBundle/Resources/public/tp_table.js

import dialog from 'tp_table/js/dialog.js';
import buttonsBar from 'tp_table/js/buttons-bar.js';
import datatables from 'tp_table/js/datatables.js';
import formFields from 'tp_table/js/form-fields.js';
import subformAsArray from 'tp_table/js/subform-as-array.js';
import imageGrid from 'tp_table/js/image-grid.js';
import entityPopup from 'tp_table/js/entity-popup.js';
import map from 'tp_table/js/map.js';

import 'tp_table/css/list.scss';
import 'tp_table/css/zoom.scss';
import 'tp_table/css/delete.scss';
import 'tp_table/css/form.scss';
import 'tp_table/css/subforms.scss';
import 'tp_table/css/buttons-bar.scss';

$(function() {
    dialog.initialize();
    buttonsBar.initialize();
    datatables.initialize();
    datatables.display();
    formFields.initialize();
    subformAsArray.initialize();
    imageGrid.initialize();
    entityPopup.initialize();
    map.initialize();

    // Display Form
    $('.table-form-row').removeClass('invisible');
});
