// src/TP/TableBundle/Resources/public/js/datatables.js

import 'datatables.net-bs';
import 'datatables.net-bs/css/dataTables.bootstrap.css';

import 'datatables.net-plugins/sorting/intl.js';
import datatables_fr from 'datatables.net-plugins/i18n/French.lang';

export default (function() {
    return {
        initialize: initialize,
        display: display
    };

    function initialize() {
        // Order with Accents
        $.fn.dataTable.ext.order.intl();

        // Support French Language
        if ($('html').attr('lang') == 'fr') {
            $.extend(true, $.fn.dataTable.defaults, {
                language: datatables_fr
            });
        }
    }

    function display(
        mode = 'list',
        container = '.tp-table-list',
        customOptions = null
    ) {
        $(container + ' #recordsList').each(function() {
            let defaultOptions = getDefaultOptions($(this), mode);
            let options = !customOptions
                ? defaultOptions
                : Object.assign(defaultOptions, customOptions);

            $(this).DataTable(options);
            $(this).show();
            $(this)
                .DataTable()
                .columns.adjust()
                .draw();
        });
    }

    function getDefaultOptions(table, mode) {
        let columnsProp = table.data('columnsprop');
        let notOrderable;
        let order;

        switch (mode) {
            case 'list':
                notOrderable = columnsProp.nbFields;
                order = columnsProp.order;
                break;
            case 'popup':
                notOrderable = 0;
                order = columnsProp.order.map(function(x) {
                    x[0] = x[0] + 1;
                    return x;
                });
                break;
            default:
                throw 'Unknown mode ("list" and "popup" allowed) : ' + mode;
        }

        let options = {
            columnDefs: [{ orderable: false, targets: notOrderable }],
            order: order
        };

        let dateOrderPosition = columnsProp.nbFields + 1;
        $.each(columnsProp.dateFields, function(position) {
            options.columnDefs.push({
                orderData: dateOrderPosition,
                targets: getActualPosition(mode, position)
            });
            options.columnDefs.push({
                visible: false,
                searchable: false,
                targets: dateOrderPosition
            });

            dateOrderPosition++;
        });

        $.each(columnsProp.hiddenFields, function(position) {
            options.columnDefs.push({
                visible: false,
                targets: getActualPosition(mode, position)
            });
        });

        return options;
    }

    function getActualPosition(mode, position) {
        return mode == 'list' ? parseInt(position) : parseInt(position) + 1;
    }
})();
